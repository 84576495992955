import React, {useRef} from 'react';
import {forEach} from 'lodash';
import {Avatar, Box, IconButton, Typography} from '@mui/material';
import {WebOutlined} from '@mui/icons-material';
import Scenario from '../../api/Scenarios/Scenario';
import SentimentLog from '../../api/SentimentLogs/SentimentLog';
import SentimentValue from '../../api/SentimentLogs/SentimentValues';
import {
  reportScenarioSummaryPanel,
  reportScenarioSummaryRow,
  reportSummaryControlsRow,
} from './ReportScenarioSummary.Styles';
import {getDurationText} from '../../Utils/ReportUtils';
import {avatarActionButton} from '../../styles/styles';

type ReportScenarioSummaryProps = {
  scenario: Scenario;
  sentimentLogs: SentimentLog[];
  testLabel: 'A' | 'B';
  duration: number;
  selectScenario: (scenario: Scenario) => any;
}

function ReportScenarioSummary(props: ReportScenarioSummaryProps) {
  const getPercentSentimentPercents = (): string => {
    const timeValues = {
      total: 0,
      positive: 0,
      neutral: 0,
      negative: 0,
    };
    forEach(props.sentimentLogs, (log) => {
      timeValues.total += log.transcript.duration;
      if (log.sentiment.value === SentimentValue.Positive) {
        timeValues.positive += log.transcript.duration;
      } else if (log.sentiment.value === SentimentValue.Negative) {
        timeValues.negative += log.transcript.duration;
      } else if (log.sentiment.value === SentimentValue.Neutral) {
        timeValues.neutral += log.transcript.duration;
      }
    });

    const positivePerc = Math.floor((timeValues.positive / timeValues.total) * 100);
    const neutralPerc = Math.floor((timeValues.neutral / timeValues.total) * 100);

    return `linear-gradient(
        to right, #666aed, #666aed ${positivePerc}%,
        #b6b6bf ${positivePerc}%, #b6b6bf ${positivePerc + neutralPerc}%,
        #ff3c2e ${positivePerc + neutralPerc}%, #ff3c2e
      )`;
  };

  const getBarStyles = () => ({
    display: 'flex',
    flex: '1 1',
    height: '2rem',
    background: getPercentSentimentPercents(),
  });

  const fireSelect = () => {
    props.selectScenario(props.scenario);
  };

  return (
    <Box sx={reportScenarioSummaryPanel}>
      <Box sx={reportScenarioSummaryRow}>
        <Typography variant="h4">{props.scenario.sceneName}</Typography>
        <Typography variant="h4">Scenario: {props.testLabel}</Typography>
        <Typography variant="h4">{getDurationText(props.duration)}</Typography>
      </Box>
      <Box sx={reportScenarioSummaryRow}>
        <Box sx={getBarStyles()} />
      </Box>
      <Box sx={reportScenarioSummaryRow}>
        <Typography variant="subtitle2">Positive</Typography>
        <Typography variant="subtitle2">Negative</Typography>
      </Box>
      <Box sx={reportSummaryControlsRow}>
        <Avatar>
          <IconButton onClick={() => fireSelect()} aria-label="add experiment" sx={avatarActionButton}>
            <WebOutlined />
          </IconButton>
        </Avatar>
      </Box>
    </Box>
  );
}

export default ReportScenarioSummary;
