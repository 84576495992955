import React, {useState, ChangeEvent, FormEvent} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import {AgencyAddClientModalActions, AgencyAddClientModalBody} from './AgencyAddClientModal.Style';

type AgencyAddClientModalProps = {
  isOpen: boolean;
  onClose: () => any;
  onSave: (name: string, email: string) => any;
};

function AgencyAddClientModal(props: AgencyAddClientModalProps) {
  const [clientName, setClientName] = useState<string>('');
  const [clientEmail, setClientEmail] = useState<string>('');

  const handleClose = () => {
    props.onClose();
    setClientName('');
    setClientEmail('');
  };

  const handleFormSubmit = (event: FormEvent<SubmitEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    submitForm();
  };

  const submitForm = () => {
    if (clientName && clientName.length > 0 && clientEmail && clientEmail.length > 0) {
      props.onSave(clientName, clientEmail);
      setClientName('');
      setClientEmail('');
    }
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleFormSubmit,
      }}
      sx={AgencyAddClientModalBody}
    >
      <DialogTitle component="div">Add Client</DialogTitle>
      <DialogContent>
        <TextField
          error={clientName === ''}
          required
          value={clientName}
          label="Client Name"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setClientName(event.target.value)}
        />
        <TextField
          error={clientEmail === ''}
          required
          value={clientEmail}
          label="Client Email"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setClientEmail(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={AgencyAddClientModalActions}>
          <Button variant="text" onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            type="submit"
            disabled={clientName === '' || clientEmail === ''}
          >
            Add Client
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default AgencyAddClientModal;
