const assetListItem = {
  display: 'flex',
  flexDirection: 'row',
  flex: '0 1',
  gap: 1,
  alignContent: 'middle',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: '#1A1B2114',
  },
  cursor: 'pointer',
};

const assetListIcon = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  flex: 0,
};

const assetListMiniDetail = {
  display: 'flex',
  flexDirection: 'column',
  flex: 6,
};

export {
  assetListItem,
  assetListIcon,
  assetListMiniDetail,
};
