import React, {ReactNode, useEffect, useState} from 'react';
import {Box, Switch, TextField, Typography} from '@mui/material';
import {cloneDeep, includes, map, remove} from 'lodash';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import Asset from '../../api/Assets/Asset';
import {
  configureAction,
  configureActionArea,
  configureActionRow,
  configureActionRowsWrapper,
  configureAssetInput,
  configureAssetPanel,
  configureAssetPanelBody,
  configureAssetPanelHeader,
  configureAssetRow,
} from './ConfigureAssetPanel.Styles';
import PanelHeader from '../PanelHeader/PanelHeader';
import PanelControlsFooter from '../PanelControlsFooter/PanelControlsFooter';

type ConfigureAssetPanelProps = {
  asset: Asset;
  actions: string[];
  onCancel: () => any;
  onSave: (asset: Asset) => any;
}

function ConfigureAssetPanel(props: ConfigureAssetPanelProps) {
  const [name, setName] = useState<string>(props.asset.name || '');
  const [unityId, setUnityId] = useState<string>(props.asset.unityId || '');
  const [x, setX] = useState<number>(props.asset.x || 0);
  const [y, setY] = useState<number>(props.asset.y || 0);
  const [z, setZ] = useState<number>(props.asset.z || 0);
  const [allowedActions, setAllowedActions] = useState<string[]>(props.asset.allowedActions);

  useEffect(() => {
    setName(props.asset.name || '');
    setUnityId(props.asset.unityId || '');
    setX(props.asset.x || 0);
    setY(props.asset.y || 0);
    setZ(props.asset.z || 0);
    setAllowedActions(props.asset.allowedActions || []);
  }, [props.asset]);

  const fireSave = () => {
    const assetToSave = {
      ...props.asset,
      name,
      unityId,
      x,
      y,
      z,
      allowedActions,
    };

    props.onSave(assetToSave);
  };

  const fireCancel = () => {
    props.onCancel();
  };

  const toggleActionAllowed = (action: string) => {
    if (isActionAllowed(action)) {
      const allowed = cloneDeep(allowedActions);
      remove(allowed, (allowedAction) => allowedAction === action);
      setAllowedActions(allowed);
    } else {
      setAllowedActions([...allowedActions, action]);
    }
  };

  const toggleActionSwitch = (event: React.MouseEvent<HTMLElement>, action: string): void => {
    event.preventDefault();
    toggleActionAllowed(action);
  };

  const isActionAllowed = (action: string): boolean => includes(allowedActions, action);

  const getActionRow = (action: string): ReactNode => (
    <Box
      key={action}
      sx={configureActionRow}
    >
      <Box sx={configureAction}>
        <DirectionsRunOutlinedIcon />
        <Typography variant="h4">{action}</Typography>
      </Box>
      <Switch checked={isActionAllowed(action)} onClick={(event) => toggleActionSwitch(event, action)} />
    </Box>
  );

  const getActionRows = (): ReactNode[] => map(props.actions, (action) => getActionRow(action));

  const saveDisabled = ():boolean => (
    name === ''
      || name === 'New Asset'
      || unityId === ''
  );

  return (
    <Box sx={configureAssetPanel}>
      <PanelHeader
        sx={configureAssetPanelHeader}
        title="Configure Asset"
      />
      <Box sx={configureAssetPanelBody}>
        <Box sx={configureAssetRow}>
          <TextField
            error={name === '' || name === 'New Asset'}
            sx={configureAssetInput}
            variant="filled"
            label="Asset Name"
            value={name}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
        </Box>
        <Box sx={configureAssetRow}>
          <TextField
            error={unityId === ''}
            sx={configureAssetInput}
            variant="filled"
            label="Unity Id"
            value={unityId}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUnityId(event.target.value);
            }}
          />
        </Box>
        <Box sx={configureAssetRow}>
          <TextField
            error={Number.isNaN(x)}
            sx={configureAssetInput}
            variant="filled"
            label="X"
            value={x}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setX(Number(event.target.value));
            }}
          />
          <TextField
            error={Number.isNaN(y)}
            sx={configureAssetInput}
            variant="filled"
            label="Y"
            value={y}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setY(Number(event.target.value));
            }}
          />
          <TextField
            error={Number.isNaN(z)}
            sx={configureAssetInput}
            variant="filled"
            label="Z"
            value={z}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setZ(Number(event.target.value));
            }}
          />
        </Box>
        <Box sx={configureActionArea}>
          <Typography>Allowed Actions</Typography>
          <Box sx={configureActionRowsWrapper}>
            {getActionRows()}
          </Box>
        </Box>
      </Box>
      <PanelControlsFooter
        onCancel={() => fireCancel()}
        onSave={() => fireSave()}
        saveDisabled={saveDisabled}
      />
    </Box>
  );
}

export default ConfigureAssetPanel;
