/* eslint-disable no-alert */
import React, {useState, ChangeEvent, FormEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, TextField, Typography, Paper} from '@mui/material';
import {useCognitoAuth} from '../../hooks/useCognitoAuth';
import {LoginScreen, CenteredLogin, LargeLogo, LogoContentVertical, LoginActions} from './Login.Style';

function Login() {
  // Auth providers...
  const cognitoAuth = useCognitoAuth();

  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  // Use case for when user must reset password...
  const [passwordReset, setPasswordReset] = useState(false);
  const [passwordResetUser, setPasswordResetUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const cognitoSignIn = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const result = await cognitoAuth.signIn(username, password);
    if (result.success) {
      navigate({pathname: `${process.env.REACT_APP_LOGIN_SUCCESS_URI}`});
    } else if (result.resetPassword) {
      setPasswordResetUser(result.user);
      setPasswordReset(true);
    } else {
      alert('The username or password is invalid');
    }
  };

  const cognitoNewPasswordRequired = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (confirmPassword !== newPassword) {
      alert('New passwords do not match...');
      return;
    }
    const result = await cognitoAuth.newPassword(passwordResetUser, newPassword);
    if (result.success) {
      navigate({pathname: `${process.env.REACT_APP_LOGIN_SUCCESS_URI}`});
    } else {
      alert(result.message);
    }
  };

  const startCognitoPasswordReset = async () => {
    setPasswordReset(true);
  };

  const cancelCognitoPasswordReset = async () => {
    setPasswordReset(false);
  };

  const loginForm = () => (
    <Paper component="form" onSubmit={cognitoSignIn}>
      <Box sx={LargeLogo}>
        <Typography sx={LogoContentVertical} variant="h1">Tupelo</Typography>
      </Box>
      <TextField
        variant="filled"
        label="Username"
        id="username"
        name="username"
        onChange={(event: ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
      />
      <TextField
        variant="filled"
        label="Password"
        id="password"
        name="password"
        type="password"
        onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
      />
      <Box sx={LoginActions}>
        <Button variant="text" onClick={startCognitoPasswordReset}>Reset Password</Button>
        <Button variant="contained" type="submit">Login</Button>
      </Box>
    </Paper>
  );

  const resetForm = () => (
    <Paper component="form" onSubmit={cognitoNewPasswordRequired}>
      <Box sx={LargeLogo}>
        <Typography sx={LogoContentVertical} variant="h1">Tupelo</Typography>
      </Box>
      <TextField
        variant="filled"
        label="Username"
        id="username"
        name="username"
        value={username}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        variant="filled"
        label="New Password"
        type="password"
        value={newPassword}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
      />
      <TextField
        variant="filled"
        label="Confirm Password"
        type="password"
        value={confirmPassword}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value)}
      />
      <Box sx={LoginActions}>
        <Button variant="text" onClick={cancelCognitoPasswordReset}>Cancel</Button>
        <Button variant="contained" type="submit">Update</Button>
      </Box>
    </Paper>
  );

  return (
    <Box sx={LoginScreen}>
      <Box sx={CenteredLogin}>
        {!passwordReset && loginForm()}
        {passwordReset && resetForm()}
      </Box>
    </Box>
  );
}

export default Login;
