import tupeloApi from '../helpers';
import Trial from './Trial';

const createTrial = async (trial: Trial): Promise<Trial> => {
  const result = await tupeloApi.post('/trials', trial);
  return result.data as Trial;
};

const updateTrial = async (trial: Trial): Promise<Trial> => {
  const result = await tupeloApi.put(`/trials/${trial.id}`, trial);
  return result.data as Trial;
};

const removeTrial = async (trialId: string): Promise<boolean> => {
  const result = await tupeloApi.delete(`/trials/${trialId}`);
  return result.data as boolean;
};

const getTrial = async (trialId: string): Promise<Trial> => {
  const result = await tupeloApi.get(`/trials/${trialId}`);
  return result.data as Trial;
};

const getTrialsForExperiment = async (experimentId: string): Promise<Trial[]> => {
  const result = await tupeloApi.get(`/experiments/${experimentId}/trials`);
  return result.data as Trial[];
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createTrial,
  updateTrial,
  removeTrial,
  getTrial,
  getTrialsForExperiment,
};
