import {panel} from '../../styles/styles';
import panelHeader from '../PanelHeader/PanelHeader.Style';

const clientPanel = {
  ...panel,
  alignSelf: 'stretch',
  flex: '1 0',
};

const clientPanelHeader = {
  ...panelHeader,
  alignSelf: 'stretch',
  justifyContent: 'space-between',
};

const clientPanelBody = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignSelf: 'stretch',
  gap: 2,
  padding: 2,
  alignContent: 'top',
};

export {
  clientPanel,
  clientPanelHeader,
  clientPanelBody,
};
