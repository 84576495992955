const panelControlsFooter = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  minHeight: '3rem',
  padding: '0.25rem 0.5rem',
  justifyContent: 'space-between',
  borderRadius: '0rem 0rem 0.625rem 0.625rem',
  backgroundColor: 'primary.light',
};

const controlButton = {
  borderRadius: '4rem',
};

export {
  panelControlsFooter,
  controlButton,
};
