import tupeloApi from '../helpers';
import Action from './Action';

const getActions = async (): Promise<Action[]> => {
  const result = await tupeloApi.get('/actions');
  return result.data as Action[];
};

export default {
  getActions,
};
