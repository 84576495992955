import React from 'react';
import {Box, Typography} from '@mui/material';
import {ArrowRight, SellOutlined} from '@mui/icons-material';
import Asset from '../../api/Assets/Asset';
import {assetListIcon, assetListItem, assetListMiniDetail} from './AssetListItem.Style';

type AssetListItemProps = {
  asset: Asset;
  onAction?: (id: string) => any;
};

function AssetListItem(props: AssetListItemProps) {
  const fireAssetClick = () => {
    props.onAction?.(props.asset.id || '');
  };

  return (
    <Box sx={assetListItem} onClick={() => fireAssetClick()}>
      <Box sx={assetListIcon}>
        <SellOutlined />
      </Box>
      <Box sx={assetListMiniDetail}>
        <Typography variant="h4">{props.asset.name}</Typography>
      </Box>
      <Box sx={assetListIcon}>
        <ArrowRight />
      </Box>
    </Box>
  );
}

export default AssetListItem;
