import React, {ReactNode, useEffect, useState} from 'react';
import {Avatar, Box, Button, IconButton, TextField, Typography} from '@mui/material';
import {EditOutlined} from '@mui/icons-material';
import {avatarActionButton, contentAreaHeader} from '../../styles/styles';
import {agencyHeaderColumn, agencyHeaderInput, agencyHeaderRow} from './AgencyHeader.Style';
import Agency from '../../api/Agencies/Agency';

type agencyHeaderProps = {
  agency?: Agency;
  isNew: Boolean;
  saveAgency: (agency: Agency) => any;
  cancelNew: () => any;
}

function AgencyHeader(props: agencyHeaderProps) {
  const [isEditState, setEditState] = useState<Boolean>(false);
  const [name, setName] = useState<string>('');
  const [street1, setStreet1] = useState<string>('');
  const [street2, setStreet2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [pointOfContact, setPointOfContact] = useState<string>('');
  const [emailList, setEmailList] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    setName(props.agency?.name || '');
    setStreet1(props.agency?.street1 || '');
    setStreet2(props.agency?.street2 || '');
    setCity(props.agency?.city || '');
    setState(props.agency?.state || '');
    setZip(props.agency?.zip || '');
    setPointOfContact(props.agency?.pointOfContact || '');
    setEmailList(props.agency?.emailList || '');
    setPhone(props.agency?.phone || '');
  }, [isEditState]);

  useEffect(() => {
    setEditState(props.isNew);
  }, [props.isNew]);

  const getStreet2 = (): ReactNode | undefined => {
    let street2Label;
    if (props.agency?.street2) {
      street2Label = (
        <Typography variant="body1">
          {props.agency?.street2}
        </Typography>
      );
    }

    return street2Label;
  };

  const getAddressCityRow = (): string => `${props.agency?.city} ${props.agency?.state} ${props.agency?.zip}`;

  const getAddressColumn = (): ReactNode => (
    <Box>
      <Typography variant="body1">
        {props.agency?.street1}
      </Typography>
      {getStreet2()}
      <Typography variant="body1">
        {getAddressCityRow()}
      </Typography>
    </Box>
  );

  const getPOCColumn = (): ReactNode => (
    <Box>
      <Typography variant="body1">
        {props.agency?.pointOfContact}
      </Typography>
      <Typography variant="body1">
        {props.agency?.emailList}
      </Typography>
      <Typography variant="body1">
        {props.agency?.phone}
      </Typography>
    </Box>
  );

  const toggleEditState = (): void => {
    setEditState(!isEditState);

    if (props.isNew) {
      props.cancelNew();
    }
  };

  const saveDisabled = (): boolean => (name === '' || emailList === '');

  const fireSave = (): void => {
    const agencyToSave = {
      id: props.agency?.id || undefined,
      name,
      street1,
      street2,
      city,
      state,
      zip,
      pointOfContact,
      emailList,
      phone,
    };

    toggleEditState();

    props.saveAgency(agencyToSave);
  };

  const getDisplayHeader = (): ReactNode => (
    <Box className="display-agency-header" sx={contentAreaHeader}>
      <Box sx={agencyHeaderRow}>
        <Typography variant="h3">
          {props.agency?.name}
        </Typography>
      </Box>
      <Box sx={agencyHeaderRow}>
        <Box sx={agencyHeaderColumn}>
          {getAddressColumn()}
        </Box>
        <Box sx={agencyHeaderColumn}>
          {getPOCColumn()}
        </Box>
        <Box>
          <Avatar>
            <IconButton onClick={() => toggleEditState()} aria-label="add experiment" sx={avatarActionButton}>
              <EditOutlined />
            </IconButton>
          </Avatar>
        </Box>
      </Box>
    </Box>
  );

  const getEditAddressRow = (): ReactNode => (
    <Box sx={agencyHeaderRow}>
      <TextField
        sx={agencyHeaderInput}
        variant="filled"
        label="City"
        value={city}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setCity(event.target.value);
        }}
      />
      <TextField
        sx={agencyHeaderInput}
        variant="filled"
        label="State"
        value={state}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setState(event.target.value);
        }}
      />
      <TextField
        sx={agencyHeaderInput}
        variant="filled"
        label="Zip"
        value={zip}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setZip(event.target.value);
        }}
      />
    </Box>
  );

  const getEditHeader = (): ReactNode => (
    <Box className="edit-agency-header" sx={contentAreaHeader}>
      <form onSubmit={fireSave}>
        <Box sx={agencyHeaderRow}>
          <TextField
            error={name === ''}
            sx={agencyHeaderInput}
            variant="filled"
            label="Agency Name"
            value={name}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
        </Box>
        <Box sx={agencyHeaderRow}>
          <Box sx={agencyHeaderColumn}>
            <TextField
              sx={agencyHeaderInput}
              variant="filled"
              label="Street"
              value={street1}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setStreet1(event.target.value);
              }}
            />
            <TextField
              sx={agencyHeaderInput}
              variant="filled"
              label="Street2"
              value={street2}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setStreet2(event.target.value);
              }}
            />
            {getEditAddressRow()}
          </Box>
          <Box sx={agencyHeaderColumn}>
            <TextField
              sx={agencyHeaderInput}
              variant="filled"
              label="Point of Contact Name"
              value={pointOfContact}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPointOfContact(event.target.value);
              }}
            />
            <TextField
              error={emailList === ''}
              sx={agencyHeaderInput}
              variant="filled"
              label="Email Address"
              value={emailList}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmailList(event.target.value);
              }}
            />
            <TextField
              sx={agencyHeaderInput}
              variant="filled"
              label="Phone"
              value={phone}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(event.target.value);
              }}
            />
          </Box>
        </Box>
        <Box sx={agencyHeaderRow}>
          <Button
            variant="text"
            onClick={() => toggleEditState()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={saveDisabled()}
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );

  const getHeader = ():ReactNode => (isEditState ? getEditHeader() : getDisplayHeader());

  return (
    <>
      {getHeader()}
    </>
  );
}

export default AgencyHeader;
