import {contentWrapper, panel} from '../../styles/styles';

const ReportContentWrapper = {
  ...contentWrapper,
  flexDirection: 'column',
  gap: 1,
  padding: '1rem',
};

const reportSummaryRow = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  alignItems: 'stretch',
  gap: 1,
};

const reportScenarioRow = {
  ...panel,
  display: 'flex',
  flex: '1 0',
  flexDirection: 'row',
  alignItems: 'stretch',
  gap: 1,
  padding: '1rem',
};

const reportScenarioRowGraphs = {
  display: 'flex',
  flex: '2 0',
  flexDirection: 'column',
  maxWidth: '1100px',
};

const reportScenarioRowTable = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
};

const heatMapWrapper = {
  display: 'flex',
  flex: '1 0',
  alignItems: 'center',
  maxHeight: '500px',
  maxWidth: '1000px',
};

export {
  ReportContentWrapper,
  reportSummaryRow,
  reportScenarioRow,
  reportScenarioRowGraphs,
  reportScenarioRowTable,
  heatMapWrapper,
};
