import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import PathConstants from './pathConstants';
import AgencyPage from '../components/AgencyPage/AgencyPage';
import Login from '../components/Login/Login';
import ReportPage from '../components/ReportPage/ReportPage';

const ExperimentPage = React.lazy(() => import('../components/ExperimentPage/ExperimentPage'));
const ClientPage = React.lazy(() => import('../components/ClientPage/ClientPage'));

const routes = createBrowserRouter([
  {
    path: PathConstants.HOME,
    element: <ClientPage />,
  },
  {
    path: PathConstants.AGENCIES,
    element: <AgencyPage />,
  },
  {
    path: PathConstants.AGENCY,
    element: <AgencyPage />,
  },
  {
    path: PathConstants.CLIENT,
    element: <ClientPage />,
  },
  {
    path: PathConstants.EXPERIMENTS,
    element: <ExperimentPage />,
  },
  {
    path: PathConstants.LOGIN,
    element: <Login />,
  },
  {
    path: PathConstants.REPORTS,
    element: <ReportPage />,
  },
]);

export default routes;
