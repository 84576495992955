/* eslint-disable import/prefer-default-export */
const agencyHeaderColumn = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
};

const agencyHeaderRow = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  '& h3': {
    color: '#000',
    fontSize: '1.75rem',
    fontWeight: 400,
    lineHeight: '2.25rem',
    display: 'block',
  },
};

const agencyHeaderInput = {
  margin: '0.25rem',
};

export {
  agencyHeaderColumn,
  agencyHeaderRow,
  agencyHeaderInput,
};
