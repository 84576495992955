import tupeloApi from '../helpers';
import Asset from './Asset';

const getAssetsForScene = async (sceneId: string): Promise<Asset[]> => {
  const result = await tupeloApi.get(`/scenes/${sceneId}/assets`);
  return result.data as Asset[];
};

const updateAsset = async (asset: Asset): Promise<Asset> => {
  const result = await tupeloApi.put(`/assets/${asset.id}`, asset);
  return result.data;
};

const addAsset = async (asset: Asset): Promise<Asset> => {
  const result = await tupeloApi.post('/assets', asset);
  return result.data;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAssetsForScene,
  updateAsset,
  addAsset,
};
