import React, {ReactNode, useEffect, useState} from 'react';
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {filter, includes, map} from 'lodash';
import ActionLog from '../../api/ActionLogs/ActionLog';
import apiActions from '../../api/Actions/apiActions';
import {convertToMins, getLogTimeExtent} from '../../Utils/ReportUtils';

type ReportActionLogsTableProps = {
  testLabel: 'A' | 'B';
  actionLogs: ActionLog[];
};

function ReportActionLogsTable(props: ReportActionLogsTableProps) {
  const [actions, setActions] = useState<string[]>([] as string[]);
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);

  useEffect(() => {
    apiActions.getActions().then((response) => {
      const actionsSansMovement = filter(response, (action) => action.id !== 'movement');
      setActions(map(actionsSansMovement, (action) => action.id));
    });

    const stime = getLogTimeExtent(props.actionLogs)[0];
    setStartTime(stime);
  }, [props.actionLogs]);

  const getTimeFromStartString = (time: Date): string => {
    const millis = time.getTime() - (startTime?.getTime() || 0);
    return convertToMins(millis / 1000);
  };

  const getLogRow = (log: ActionLog): ReactNode => (
    <TableRow
      key={log.id}
      sx={{'&:last-child td, &:last-child th': {border: 0}}}
    >
      <TableCell component="th" scope="row">
        {log.type}
      </TableCell>
      <TableCell align="right">{getTimeFromStartString(new Date(log.timestamp))}</TableCell>
      <TableCell align="right">{log.objectName}</TableCell>
      <TableCell align="right">{Number(log.x).toFixed(3)}</TableCell>
      <TableCell align="right">{Number(log.y).toFixed(3)}</TableCell>
      <TableCell align="right">{Number(log.z).toFixed(3)}</TableCell>
    </TableRow>
  );

  const getLogRows = (): ReactNode[] => {
    const filteredLogs = filter(props.actionLogs, (log) => includes(actions, log.type));
    return map(filteredLogs, (log) => getLogRow(log));
  };

  return (
    <>
      <Typography variant="h4">
        Scenario: {props.testLabel}
      </Typography>
      <Box>controls</Box>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell align="right">Time</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">x</TableCell>
              <TableCell align="right">y</TableCell>
              <TableCell align="right">z</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getLogRows()}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ReportActionLogsTable;
