import React, {useRef, useEffect, useState} from 'react';
import * as d3 from 'd3';
import {filter} from 'lodash';
import ActionLog from '../../api/ActionLogs/ActionLog';
import * as ReportUtils from '../../Utils/ReportUtils';
import ActionType from '../../api/Actions/ActionType';
import SentimentLog from '../../api/SentimentLogs/SentimentLog';
import {convertToMins} from '../../Utils/ReportUtils';

type TimelineProps = {
  actionLogs: ActionLog[],
  sentimentLogs: SentimentLog[],
  selectSentiment?: (log?: SentimentLog) => any,
  selectedSentiment?: SentimentLog,
}

function Timeline(props: TimelineProps) {
  const d3Container = useRef<SVGSVGElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(400);

  const dimensions = {
    height: 70,
    width: 400,
    padding: {
      top: 10,
      right: 30,
      bottom: 20,
      left: 30,
    },
  };

  type Scales = {
    x: d3.ScaleTime<any, any>;
  }

  const borderColor = d3.rgb(150, 150, 150).toString();
  const selectedColor = d3.rgb(252, 186, 3).toString();

  const [scales, setScales] = useState<Scales>();

  const initialize = () => {
    if (d3Container.current) {
      const svg = d3.select(`#${d3Container?.current.id}`);

      const width = d3Container?.current?.clientWidth || dimensions.width;
      setContainerWidth(width);

      const plotHeight = dimensions.height - dimensions.padding.top - dimensions.padding.bottom;
      const plotWidth = width - dimensions.padding.left - dimensions.padding.right;

      svg.append('rect')
        .attr('x', dimensions.padding.left)
        .attr('y', dimensions.padding.top)
        .attr('width', plotWidth)
        .attr('height', plotHeight)
        .style('stroke', borderColor)
        .style('fill', 'transparent');

      svg.append('line')
        .attr('x1', (plotWidth / 2) + dimensions.padding.left)
        .attr('x2', (plotWidth / 2) + dimensions.padding.left)
        .attr('y1', dimensions.padding.top)
        .attr('y2', plotHeight + dimensions.padding.top)
        .style('stroke', borderColor)
        .style('stroke-width', 1);
    }
  };

  const updateD3Parameters = () => {
    if (props.actionLogs) {
      const plotWidth = containerWidth - dimensions.padding.left - dimensions.padding.right;

      const timeExtent = ReportUtils.getLogTimeExtent(props.actionLogs);
      const scaleX = d3.scaleTime()
        .domain(timeExtent)
        .range([dimensions.padding.left, dimensions.padding.left + plotWidth]);

      setScales({
        x: scaleX,
      });
    }
  };

  const update = () => {
    if (scales) {
      const timeExtent = ReportUtils.getLogTimeExtent(props.actionLogs);
      const startTime = timeExtent[0].getTime();
      const endTime = timeExtent[1].getTime() - startTime;
      const middleTime = endTime / 2;
      const startX = scales.x(timeExtent[0]);
      const endX = scales.x(timeExtent[1]);
      const middleX = ((endX - startX) / 2) + startX;

      const timeLine = d3.select('#tupelo-timeline');

      timeLine.selectAll('.axis-label').remove();
      timeLine.selectAll('.event').remove();
      timeLine.selectAll('.sentiment-rect').remove();

      timeLine.append('text')
        .classed('axis-label', true)
        .attr('x', startX)
        .attr('y', 65)
        .text(convertToMins(0));

      timeLine.append('text')
        .classed('axis-label', true)
        .attr('x', middleX)
        .attr('y', 65)
        .style('text-anchor', 'middle')
        .text(convertToMins(middleTime / 1000));

      timeLine.append('text')
        .classed('axis-label', true)
        .attr('x', endX)
        .attr('y', 65)
        .style('text-anchor', 'end')
        .text(convertToMins(endTime / 1000));

      const eventLogsEnter = timeLine.selectAll('.event')
        .data(filter(props.actionLogs, (log) => log.type !== ActionType.Movement && log.type !== ActionType.Comment))
        .enter();

      eventLogsEnter.append('circle')
        .classed('event', true)
        .attr('cy', 40)
        .attr('cx', (d) => scales.x(new Date(d.timestamp)))
        .attr('r', 5)
        .style('fill', (d) => ReportUtils.getColorByType(d.type));

      const sentimentLogsEnter = timeLine.selectAll('.sentiment-rect')
        .data(props.sentimentLogs)
        .enter();

      sentimentLogsEnter.append('rect')
        .classed('sentiment-rect', true)
        .attr('x', (d) => scales.x(new Date(d.created)))
        .attr('y', 15)
        .attr('height', 10)
        .attr('width', (d) => scales.x(new Date(startTime + (d.transcript.duration * 1000))) - startX)
        .style('fill', (d) => ReportUtils.getSentenceColor(d.sentiment.value))
        .style('stroke', (d) => (
          d.id === props.selectedSentiment?.id ? selectedColor : ReportUtils.getSentenceColor(d.sentiment.value)))
        .style('stroke-width', 2)
        .on('click', (event, d) => {
          props.selectSentiment?.(d);
        });
    }
  };

  useEffect(initialize, []);
  useEffect(update, [scales]);
  useEffect(updateD3Parameters, [props]);

  return (
    <svg
      id="tupelo-timeline"
      height={dimensions.height}
      ref={d3Container}
    />
  );
}

export default Timeline;
