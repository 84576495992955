const AgencyAddClientModalBody = {
  display: 'flex',
  flexDirection: 'column',
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    width: '30rem',
    '& .MuiTextField-root': {
      margin: '.75rem 0 0',
    },
  },
};

const AgencyAddClientModalActions = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  flex: '1',
  padding: '0 1rem',
};

export {
  AgencyAddClientModalBody,
  AgencyAddClientModalActions,
};
