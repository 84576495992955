import tupeloApi from '../helpers';
import Agency from './Agency';

const getAgencies = async (): Promise<Agency[]> => {
  const result = await tupeloApi.get('/agencies');
  return result.data as Agency[];
};

const getAgency = async (agencyId: string): Promise<Agency> => {
  const result = await tupeloApi.get(`/agencies/${agencyId}`);
  return result.data as Agency;
};

const updateAgency = async (agency: Agency): Promise<Agency> => {
  const result = await tupeloApi.put(`/agencies/${agency.id}`, agency);
  return result.data as unknown as Agency;
};

const addAgency = async (agency: Agency): Promise<Agency> => {
  const result = await tupeloApi.post('/agencies', agency);
  return result.data as unknown as Agency;
};

export default {
  getAgencies,
  getAgency,
  updateAgency,
  addAgency,
};
