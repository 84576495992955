import {contentAreaWrapper} from '../../styles/styles';

const agencies = {
  display: 'flex',
};

const contentRow = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  gap: 1,
};

const contentRowGrow = {
  flex: '1 0',
};

const agencyAreaWrapper = {
  ...contentAreaWrapper,
  maxWidth: '100vw',
};

export {
  agencies,
  contentRow,
  contentRowGrow,
  agencyAreaWrapper,
};
