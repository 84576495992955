import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import {ArrowBackIosNew} from '@mui/icons-material';
import dayjs from 'dayjs';
import Trial from '../../api/Trials/Trial';
import {reportInfoEmail, reportInfoPanel, reportInfoRow} from './ReportInfoStyles';

type ReportInfoProps = {
  trial?: Trial;
  experimentName?: string;
  duration: string;
  onReturn: () => any;
};

function ReportInfo(props: ReportInfoProps) {
  const onFireReturn = () => {
    props.onReturn();
  };

  return (
    <Box sx={reportInfoPanel}>
      <Box sx={reportInfoRow}>
        <IconButton aria-label="back" onClick={() => onFireReturn()}>
          <ArrowBackIosNew />
        </IconButton>
        <Typography variant="h4">{props.experimentName}</Typography>
      </Box>
      <Box sx={reportInfoRow}>
        <Typography variant="h2">{props.trial?.name}</Typography>
      </Box>
      <Box sx={[reportInfoRow]}>
        <Typography sx={reportInfoEmail} variant="subtitle2">{props.trial?.email}</Typography>
      </Box>
      <Box sx={reportInfoRow}>
        <Typography>{dayjs(props.trial?.completed || new Date()).format('MMM D')}</Typography>
        <Typography variant="subtitle2">{props.duration}</Typography>
      </Box>
    </Box>
  );
}

export default ReportInfo;
