import {flex, panel} from '../../styles/styles';
import panelHeader from '../PanelHeader/PanelHeader.Style';

const configureAssetPanel = {
  ...panel,
  alignItems: 'stretch',
  flex: '1 0',
};

const configureAssetPanelHeader = {
  ...panelHeader,
  justifyContent: 'space-between',
};

const configureAssetPanelBody = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  gap: 2,
  padding: 2,
  alignItems: 'stretch',
  justifyContent: 'start',
};

const configureAssetRow = {
  display: 'flex',
  flex: '0 1',
  alignItems: 'stretch',
  flexDirection: 'row',
};

const configureAssetInput = {
  display: 'flex',
  flex: '1 0',
  margin: '.125em',
};

const configureActionArea = {
  ...configureAssetRow,
  flex: '1 0',
  flexDirection: 'column',
};

const configureActionRowsWrapper = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  overflowY: 'auto',
  justifyContent: 'start',
  alignItems: 'stretch',
};

const configureActionRow = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: '#1A1B2114',
  },
  cursor: 'pointer',
  padding: '.5rem',
};

const configureAction = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'row',
  justifyContent: 'start',
  gap: 1,
};

export {
  configureAssetPanel,
  configureAssetPanelHeader,
  configureAssetPanelBody,
  configureAssetRow,
  configureAssetInput,
  configureActionArea,
  configureActionRowsWrapper,
  configureActionRow,
  configureAction,
};
