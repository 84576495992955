import {panel} from '../../styles/styles';

const selectedClientPanel = {
  ...panel,
  alignSelf: 'stretch',
  flex: '1 0',
  justifyContent: 'space-between',
};

const selectedClientHeaderRow = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  '& h3': {
    color: '#000',
    fontSize: '1.75rem',
    fontWeight: 400,
    lineHeight: '2.25rem',
    display: 'block',
  },
};

export {
  selectedClientPanel,
  selectedClientHeaderRow,
};
