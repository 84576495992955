import {panel} from '../../styles/styles';
import panelHeader from '../PanelHeader/PanelHeader.Style';

const scenePanel = {
  ...panel,
  alignSelf: 'stretch',
  flex: '1 0',
};

const scenePanelHeader = {
  ...panelHeader,
  alignSelf: 'stretch',
  justifyContent: 'space-between',
};

const scenePanelBody = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignSelf: 'stretch',
  gap: 2,
  padding: 2,
  alignContent: 'top',
};

export {
  scenePanel,
  scenePanelHeader,
  scenePanelBody,
};
