import React, {useEffect, useState} from 'react';
import {AppBar, Button, Toolbar, Typography} from '@mui/material';
import {useCognitoAuth} from '../../hooks/useCognitoAuth';

type NavBarProps = {
  title: string;
}

function NavBar(props: NavBarProps) {
  // Auth providers...
  const cognitoAuth = useCognitoAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(cognitoAuth.isCognitoAuthenticated);
  }, [cognitoAuth]);

  const logout = async () => {
    cognitoAuth.cognitoSignOut();
  };

  return (
    <AppBar sx={{flex: '0 1'}} position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
          {props.title}
        </Typography>
        {isAuthenticated && (<Button color="inherit" onClick={logout}>Logout</Button>)}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
