import React, {ReactNode} from 'react';
import {Box} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {map} from 'lodash';
import Scene from '../../api/Scenes/Scene';
import {scenePanel, scenePanelBody, scenePanelHeader} from './AgencyScenesPanel.Style';
import PanelHeader from '../PanelHeader/PanelHeader';
import AgencySceneListItem from '../AgencySceneListItem/AgencySceneListItem';

type AgencyScenesProps = {
  scenes: Scene[];
  onListItemAction: (sceneId: string) => any;
  onNewScene: () => any;
};

function AgencyScenesPanel(props: AgencyScenesProps) {
  const getSceneItem = (scene: Scene): ReactNode => (
    <AgencySceneListItem
      key={scene.id}
      scene={scene}
      onAction={(id) => fireListItemAction(id)}
    />
  );

  const getSceneItems = (scenes: Scene[]) => map(
    scenes,
    (scene) => getSceneItem(scene),
  );

  const fireListItemAction = (id: string) => {
    props.onListItemAction?.(id);
  };

  const fireNewScene = (): void => {
    props.onNewScene();
  };

  return (
    <Box sx={scenePanel}>
      <PanelHeader
        sx={scenePanelHeader}
        badgeCount={props.scenes?.length ?? 0}
        title="Scenes"
        actionButtonIcon={<AddIcon />}
        onAction={() => fireNewScene()}
      />
      <Box sx={scenePanelBody}>
        {getSceneItems(props.scenes)}
      </Box>
    </Box>
  );
}

export default AgencyScenesPanel;
