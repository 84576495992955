enum ActionType {
  Drop = 'drop',
  Grab = 'grab',
  Movement = 'movement',
  Look = 'look',
  Comment = 'comment',
  Buy = 'buy',
  Find = 'find',
}

export default ActionType;
