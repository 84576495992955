import {Avatar, Badge, Box, IconButton, Typography, badgeClasses} from '@mui/material';
import React, {ReactNode} from 'react';
import panelHeader from './PanelHeader.Style';
import {avatarActionButton} from '../../styles/styles';

type PanelHeaderProps = {
  title: string;
  badgeCount?: number;
  sx?: any;
  actionButtonIcon?: ReactNode;
  onAction?: () => any;
  actionDisabled?: boolean;
}

function PanelHeader(props: PanelHeaderProps) {
  const renderAvatarSection = () => {
    let section: ReactNode;
    if (props.actionButtonIcon && props.onAction) {
      section = (
        <Avatar>
          <IconButton
            sx={avatarActionButton}
            onClick={() => props.onAction?.()}
            disabled={props.actionDisabled}
          >
            {props.actionButtonIcon}
          </IconButton>
        </Avatar>
      );
    } else {
      section = (<Box>&nbsp;</Box>);
    }
    return section;
  };

  const getBadge = (): ReactNode | undefined => {
    let badge = (<Box>&nbsp;</Box>);

    if (props.badgeCount !== undefined) {
      badge = <Badge>({props.badgeCount})</Badge>;
    }

    return badge;
  };

  return (
    <Box sx={props.sx ?? panelHeader}>
      {getBadge()}
      <Typography variant="h3" color="primary">{props.title}</Typography>
      {renderAvatarSection()}
    </Box>
  );
}

export default PanelHeader;
