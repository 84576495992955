import React from 'react';
import {Box} from '@mui/material';
import {map} from 'lodash';
import {Add} from '@mui/icons-material';
import Client from '../../api/Clients/Client';
import {clientPanel, clientPanelBody, clientPanelHeader} from './AgencyClientsPanel.Style';
import PanelHeader from '../PanelHeader/PanelHeader';
import AgencyClientListItem from '../AgencyClientListItem/AgencyClientListItem';

type AgencyClientsProps = {
  clients: Client[];
  onClientHeaderButtonClick: () => any;
  onListItemAction: (clientId: string) => any;
};

function AgencyClientsPanel(props: AgencyClientsProps) {
  const getClientItems = (clients: Client[]) => map(
    clients,
    (client) => (
      <AgencyClientListItem
        key={client.id}
        client={client}
        onAction={(id) => fireListItemAction(id)}
      />
    ),
  );

  const fireListItemAction = (id: string) => {
    props.onListItemAction?.(id);
  };

  return (
    <Box sx={clientPanel}>
      <PanelHeader
        sx={clientPanelHeader}
        badgeCount={props.clients?.length ?? 0}
        title="Clients"
        actionButtonIcon={<Add />}
        onAction={props.onClientHeaderButtonClick}
      />
      <Box sx={clientPanelBody}>
        {getClientItems(props.clients)}
      </Box>
    </Box>
  );
}

export default AgencyClientsPanel;
