import {panel} from '../../styles/styles';
import panelHeader from '../PanelHeader/PanelHeader.Style';

const configureScenePanel = {
  ...panel,
  alignItems: 'stretch',
  flex: '1 0',
};

const configureScenePanelHeader = {
  ...panelHeader,
  justifyContent: 'space-between',
};

const configureScenePanelBody = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  gap: 2,
  padding: 2,
  alignItems: 'stretch',
  justifyContent: 'start',
};

const configureSceneRow = {
  display: 'flex',
  flex: '0 1',
  alignItems: 'stretch',
  flexDirection: 'row',
};

const configureSceneInput = {
  display: 'flex',
  flex: '1 0',
  margin: '.125em',
};

const backgroundRow = {
  ...configureSceneRow,
  flex: '1 0',
  background: 'cyan',
};

export {
  configureScenePanel,
  configureScenePanelHeader,
  configureScenePanelBody,
  configureSceneRow,
  configureSceneInput,
  backgroundRow,
};
