import React, {ReactNode, Suspense, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Box} from '@mui/material';
import {cloneDeep, filter, find, map} from 'lodash';
import NavBar from '../NavBar/NavBar';
import Agency from '../../api/Agencies/Agency';
import apiAgency from '../../api/Agencies/apiAgencies';
import {appWrapper, contentAreaWrapper, contentWrapper} from '../../styles/styles';
import NavBarListItem from '../NavbarList/NavBarListItem';
import NavBarList from '../NavbarList/NavBarList';
import AgencyHeader from '../AgencyHeader/AgencyHeader';
import {agencyAreaWrapper, contentRow, contentRowGrow} from './AgencyPage.styles';
import AgencyClientsPanel from '../AgencyClientsPanel/AgencyClientsPanel';
import apiClients from '../../api/Clients/apiClients';
import Client from '../../api/Clients/Client';
import Scene from '../../api/Scenes/Scene';
import apiScenes from '../../api/Scenes/apiScenes';
import AgencySelectedClientsPanel from '../AgencySelectedClientPanel/AgencySelectedClientPanel';
import AgencyScenesPanel from '../AgencyScenesPanel/AgencyScenesPanel';
import ConfigureScenePanel from '../ConfigureScenePanel/ConfigureScenePanel';
import {Secure} from '../HOC/Secure';
import Asset from '../../api/Assets/Asset';
import ScenesAssetPanel from '../SceneAssetsPanel/SceneAssetsPanel';
import apiAssets from '../../api/Assets/apiAssets';
import apiActions from '../../api/Actions/apiActions';
import Action from '../../api/Actions/Action';
import ConfigureAssetPanel from '../ConfigureAssetPanel/ConfigureAssetPanel';
import AgencyAddClientModal from '../AgencyAddClientModal/AgencyAddClientModal';

function AgencyPage() {
  const {agencyId} = useParams();
  const navigate = useNavigate();

  const [agencies, setAgencies] = useState<Agency[]>([] as Agency[]);
  const [selectedAgency, setSelectedAgency] = useState<Agency | undefined>(undefined);
  const [isNewAgencyState, setNewAgencyState] = useState<boolean>(false);

  const [selectedClient, setSelectedClient] = useState<Client | undefined>(undefined);
  const [clients, setClients] = useState<Client[]>([] as Client[]);

  const [addingClient, setAddingClient] = useState<boolean>(false);

  const [selectedScene, setSelectedScene] = useState<Scene | undefined>(undefined);
  const [scenes, setScenes] = useState<Scene[]>([] as Scene[]);

  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>(undefined);
  const [assets, setAssets] = useState<Asset[]>([] as Asset[]);

  const [actions, setActions] = useState<Action[]>([] as Action[]);

  useEffect(() => {
    if (!agencyId) {
      navigate(`/agencies/${process.env.REACT_APP_AGENCY_ID}`);
    }

    apiActions.getActions().then((result) => {
      setActions(result);
    });
  }, []);

  useEffect(() => {
    if (agencyId) {
      apiAgency.getAgency(agencyId).then((response) => {
        setSelectedAgency(response);
        setSelectedClient(undefined);
        setSelectedScene(undefined);
        setSelectedAsset(undefined);
      });
    }
  }, [agencyId]);

  useEffect(() => {
    if (selectedAgency && selectedAgency.id) {
      apiAgency.getAgencies().then((response) => {
        setAgencies(response);
      });

      updateClients();
    }
  }, [selectedAgency]);

  useEffect(() => {
    setSelectedAsset(undefined);
    setSelectedScene(undefined);
    if (selectedClient && selectedClient.id) {
      apiScenes.getScenesForClient(selectedClient.id).then((response) => {
        setScenes(response);
      });
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedScene && selectedScene.id) {
      apiAssets.getAssetsForScene(selectedScene.id).then((response) => {
        setAssets(response);
      });
    }
  }, [selectedScene]);

  const updateClients = async () => {
    if (selectedAgency && selectedAgency.id) {
      apiClients.getClients(selectedAgency.id).then((response) => {
        setClients(response);
      });
    }
  };

  const selectAgency = (newAgencyId?: string): void => {
    setSelectedClient(undefined);
    if (isNewAgencyState) {
      const newAgency = find(agencies, (agency) => agency.id === newAgencyId);
      cancelNewAgencyState(newAgency);
    } else {
      navigate(`/agencies/${newAgencyId}`);
    }
  };

  const selectClient = (clientId: string): void => {
    setSelectedScene(undefined);
    const clientToSelect = find(clients, (client) => clientId === client.id);
    if (clientToSelect) {
      setSelectedClient(clientToSelect);
    } else {
      setSelectedClient(undefined);
    }
  };

  const selectScene = (id: string): void => {
    const sceneToSelect = find(scenes, (scene) => scene.id === id);
    if (sceneToSelect) {
      setSelectedScene(sceneToSelect);
    } else {
      setSelectedScene(undefined);
    }
  };

  const selectAsset = (id: string): void => {
    const assetToSelect = find(assets, (asset) => asset.id === id);
    if (assetToSelect) {
      setSelectedAsset(assetToSelect);
    } else {
      setSelectedAsset(undefined);
    }
  };

  const goToClientPage = (clientId: string): void => {
    navigate(`/clients/${clientId}`);
  };

  const addNewAgency = (): void => {
    const newAgency = {
      name: 'New Agency',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      pointOfContact: '',
      emailList: '',
      phone: '',
    } as unknown as Agency;

    const updatedAgencies = cloneDeep(agencies);
    updatedAgencies.push(newAgency);

    setSelectedClient(undefined);
    setClients([]);
    setSelectedScene(undefined);
    setAgencies(updatedAgencies);
    setSelectedAgency(newAgency);
    setNewAgencyState(true);
  };

  const saveAgency = async (agency: Agency) => {
    if (agency.id) {
      const updatedAgency = await apiAgency.updateAgency(agency);
      setSelectedAgency(updatedAgency);
    } else {
      const savedAgency = await apiAgency.addAgency(agency);
      selectAgency(savedAgency.id);
    }

    const updatedClients = await apiAgency.getAgencies();
    setAgencies(updatedClients);
  };

  const cancelNewAgencyState = (agencyToSelect?: Agency): void => {
    const clientsWithId = filter(agencies, (agency) => !!agency.id);

    setNewAgencyState(false);
    setAgencies(clientsWithId);

    if (agencyToSelect) {
      setSelectedAgency(agencyToSelect);
    } else {
      setSelectedAgency(clientsWithId[0]);
    }
    setNewAgencyState(true);
  };

  const addNewScene = (): void => {
    if (selectedClient && selectedClient.id) {
      const newScene = {
        name: 'New Scene',
        clientId: selectedClient.id,
        url: '',
        assetName: '',
        imageStringBase64: '',
        imageMimeType: '',
        imagePath: '',
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0,
      };

      const updatedScenes = cloneDeep(scenes);
      updatedScenes.push(newScene);

      setScenes(updatedScenes);
      setSelectedScene(newScene);
      selectAsset('');
      setAssets([]);
    }
  };

  const cancelScenes = (): void => {
    const scenesWithIds = filter(scenes, (scene) => !!scene.id);

    setScenes(scenesWithIds);
    selectScene('');
  };

  const saveScene = async (sceneToSave: Scene) => {
    if (sceneToSave.id) {
      const updatedScene = await apiScenes.updateScene(sceneToSave);
      setSelectedScene(updatedScene);
    } else {
      const savedScene = await apiScenes.addScene(sceneToSave);
      selectScene(savedScene.id || '');
    }

    if (selectedClient && selectedClient.id) {
      const updatedScenes = await apiScenes.getScenesForClient(selectedClient.id);
      setScenes(updatedScenes);
    }
  };

  const addNewAsset = (): void => {
    if (selectedScene && selectedScene.id) {
      const newAsset = {
        name: 'New Asset',
        unityId: '',
        sceneId: selectedScene.id,
        x: 0,
        y: 0,
        z: 0,
        allowedActions: filter(getActionIds(), (action) => action !== 'movement'),
      };

      const updatedAssets = cloneDeep(assets);
      updatedAssets.push(newAsset);

      setAssets(updatedAssets);
      setSelectedAsset(newAsset);
    }
  };

  const cancelAsset = (): void => {
    const assetsWithIds = filter(assets, (asset) => !!asset.id);

    setAssets(assetsWithIds);
    selectAsset('');
  };

  const saveAsset = async (assetToSave: Asset): Promise<void> => {
    if (assetToSave.id) {
      const updatedAsset = await apiAssets.updateAsset(assetToSave);
      setSelectedAsset(updatedAsset);
    } else {
      const savedAsset = await apiAssets.addAsset(assetToSave);
      selectAsset(savedAsset.id || '');
    }

    if (selectedScene && selectedScene.id) {
      const updatedAssets = await apiAssets.getAssetsForScene(selectedScene.id);
      setAssets(updatedAssets);
    }
  };

  const getActionIds = (): string[] => map(actions, (action) => action.id);

  const getNavBarListItem = (agency: Agency): NavBarListItem => {
    const menuItem = {
      itemId: agency.id || '',
      label: `for ${agency.name}`,
      isSelected: selectedAgency && agency.id === selectedAgency.id,
    };

    return menuItem;
  };

  const getNavBarListItems = (): NavBarListItem[] => map(agencies, (agency) => getNavBarListItem(agency));

  const getSelectedClientPanel = (): ReactNode | undefined => {
    let clientPanel;

    if (selectedClient) {
      clientPanel = (
        <AgencySelectedClientsPanel
          client={selectedClient}
          cancel={() => selectClient('')}
          goToClientPage={(id) => goToClientPage(id)}
        />
      );
    }

    return clientPanel;
  };

  const updateClientsOnSuccessfulAdd = async (id: string) => {
    const clientsList = clients.filter((client) => client.id === id);
    if (clientsList.length === 0) {
      await updateClients();
    }
    selectClient(id);
  };

  const getClientsPanel = (): ReactNode | undefined => {
    let clientsPanel;

    if (!selectedClient) {
      clientsPanel = (
        <AgencyClientsPanel
          clients={clients}
          onClientHeaderButtonClick={openAddClientModal}
          onListItemAction={updateClientsOnSuccessfulAdd}
        />
      );
    }

    return clientsPanel;
  };

  const getScenesPanel = (): ReactNode | undefined => {
    let scenesPanel;

    if (selectedClient && !selectedAsset) {
      scenesPanel = (
        <AgencyScenesPanel
          scenes={scenes}
          onNewScene={() => addNewScene()}
          onListItemAction={(id) => selectScene(id)}
        />
      );
    }

    return scenesPanel;
  };

  const getConfigureScenePanel = (): ReactNode | undefined => {
    let configureScenePanel;

    if (selectedScene) {
      configureScenePanel = (
        <Box sx={contentAreaWrapper}>
          <ConfigureScenePanel
            scene={selectedScene}
            onCancel={() => cancelScenes()}
            onSave={(scene) => saveScene(scene)}
          />
          <ScenesAssetPanel
            assets={assets}
            onListItemAction={(assetId) => selectAsset(assetId)}
            onNewAsset={() => addNewAsset()}
            newDisabled={!!selectedScene && !selectedScene.id}
          />
        </Box>
      );
    }

    return configureScenePanel;
  };

  const getConfigureAssetPanel = (): ReactNode | undefined => {
    let configureAssetPanel;

    if (selectedAsset) {
      configureAssetPanel = (
        <ConfigureAssetPanel
          asset={selectedAsset}
          actions={getActionIds()}
          onCancel={() => cancelAsset()}
          onSave={(asset) => saveAsset(asset)}
        />
      );
    }

    return configureAssetPanel;
  };

  const openAddClientModal = () => setAddingClient(true);
  const closeAddClientModal = () => setAddingClient(false);

  const addNewClientHandler = async (name: string, email: string) => {
    if (selectedAgency?.id) {
      const newClient = {
        agencyId: selectedAgency.id,
        name,
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        pointOfContact: '',
        email,
        phone: '',
      };
      const savedClient = await apiClients.addClient(newClient);
      if (savedClient && savedClient.id) updateClientsOnSuccessfulAdd(savedClient.id);
    }
    setAddingClient(false);
  };

  return (
    <Suspense>
      <Box sx={appWrapper}>
        <NavBar title="Agencies" />
        <Box component="main" sx={contentWrapper}>
          <NavBarList
            items={getNavBarListItems()}
            showBack={false}
            selectListItem={(itemId) => selectAgency(itemId)}
            addNew={() => addNewAgency()}
          />
          <Box sx={agencyAreaWrapper}>
            <Box sx={contentRow}>
              <AgencyHeader
                agency={selectedAgency}
                saveAgency={(agency) => saveAgency(agency)}
                isNew={isNewAgencyState}
                cancelNew={() => cancelNewAgencyState()}
              />
              {getSelectedClientPanel()}
              <AgencyAddClientModal
                isOpen={addingClient}
                onClose={closeAddClientModal}
                onSave={addNewClientHandler}
              />
            </Box>
            <Box sx={[contentRow, contentRowGrow]}>
              {getClientsPanel()}
              {getScenesPanel()}
              {getConfigureScenePanel()}
              {getConfigureAssetPanel()}
            </Box>
          </Box>
        </Box>
      </Box>
    </Suspense>
  );
}

export default Secure(AgencyPage);
