const panelHeader = {
  display: 'flex',
  flexDirection: 'row',
  height: '3rem',
  padding: '0rem 0.5rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexShrink: 0,
  borderRadius: '0.625rem 0.625rem 0rem 0rem',
  backgroundColor: 'primary.light',
};

export default panelHeader;
