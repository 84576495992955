const NavBarListDrawer = {
  display: 'flex',
  width: '22.5rem',
  height: '49.8125rem',
  flexDirection: 'column',
  alignItems: 'center',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    top: '4.25rem',
    width: '22.5rem',
    boxSizing: 'border-box',
  },
};

const navBarListWrapper = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  maxHeight: '33rem',
  overflow: 'auto',
};

const NavBarListList = {
  // display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const NavBarListTitle = {
  display: 'flex',
  fontSize: '1.375rem',
  fontWeight: 400,
  color: 'text.primary',
  height: '3.5rem',
  padding: '0.5rem 0.5rem 0.5rem 1rem',
  gap: '1rem',
  flexShrink: 0,
  alignSelf: 'stretch',
  '& button': {
    display: 'flex',
  },
  '& h3': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.375rem',
    fontWeight: 400,
    color: 'text.primary',
    flex: '1 0 0',
  },
};

const NavBarListListItem = {
  display: 'flex',
  height: '3.5rem',
  alignItems: 'center',
  gap: '0.75rem',
  flexShrink: 0,
  alignSelf: 'stretch',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'action.hover',
  },
  '&.current': {
    backgroundColor: 'action.selected',
  },
};

const NavBarListItemLabel = {
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
};

const NavBarListAddButton = {
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  justifyContent: 'center',
};

export {
  NavBarListDrawer,
  NavBarListAddButton,
  navBarListWrapper,
  NavBarListListItem,
  NavBarListItemLabel,
  NavBarListList,
  NavBarListTitle,
};
