import tupeloApi from '../helpers';
import Client from './Client';

const getClients = async (agencyId?: string): Promise<Client[]> => {
  let clients = [];
  if (agencyId) {
    const result = await tupeloApi.get(`/agencies/${agencyId}/clients`);
    clients = result.data;
  }
  return clients as Client[];
};

const getClient = async (clientId: string): Promise<Client> => {
  const result = await tupeloApi.get(`/clients/${clientId}`);
  return result.data as Client;
};

const updateClient = async (client: Client): Promise<Client> => {
  const result = await tupeloApi.put(`/clients/${client.id}`, client);
  return result.data as unknown as Client;
};

const addClient = async (client: Client): Promise<Client> => {
  const result = await tupeloApi.post('/clients', client);
  return result.data as unknown as Client;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getClients,
  getClient,
  updateClient,
  addClient,
};
