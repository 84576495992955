import React from 'react';
import {Box} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {map} from 'lodash';
import Asset from '../../api/Assets/Asset';
import PanelHeader from '../PanelHeader/PanelHeader';
import {sceneAssetPanel, sceneAssetPanelBody, sceneAssetPanelHeader} from './SceneAssetPanel.Styles';
import AssetListItem from '../AssetListItem/AssetListItem';

type SceneAssetsPanelProps = {
  assets: Asset[],
  onListItemAction: (assetId: string) => any;
  onNewAsset: () => any;
  newDisabled: boolean;
}

function ScenesAssetPanel(props: SceneAssetsPanelProps) {
  const getAssetItem = (asset: Asset) => (
    <AssetListItem
      key={asset.id || 'new'}
      asset={asset}
      onAction={(id) => fireListItemAction(id)}
    />
  );

  const getAssetItems = (assets: Asset[]) => map(
    assets,
    (asset) => getAssetItem(asset),
  );

  const fireListItemAction = (id: string) => {
    props.onListItemAction?.(id);
  };

  const fireNewAsset = (): void => {
    props.onNewAsset();
  };

  return (
    <Box sx={sceneAssetPanel}>
      <PanelHeader
        sx={sceneAssetPanelHeader}
        badgeCount={props.assets?.length ?? 0}
        title="Assets"
        actionDisabled={!!props.newDisabled}
        actionButtonIcon={<AddIcon />}
        onAction={() => fireNewAsset()}
      />
      <Box sx={sceneAssetPanelBody}>
        {getAssetItems(props.assets)}
      </Box>
    </Box>
  );
}

export default ScenesAssetPanel;
