import {useNavigate, useParams} from 'react-router-dom';
import React, {ReactNode, Suspense, useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {filter, findIndex, forEach, map} from 'lodash';
import dayjs from 'dayjs';
import {Secure} from '../HOC/Secure';
import HeatMap from '../HeatMap/HeatMap.D3';
import Timeline from '../Timeline/Timeline.d3';
import SentimentLog from '../../api/SentimentLogs/SentimentLog';
import NavBar from '../NavBar/NavBar';
import {appWrapper} from '../../styles/styles';
import apiTrials from '../../api/Trials/apiTrials';
import Trial from '../../api/Trials/Trial';
import Experiment from '../../api/Experiments/Experiment';
import Scenario from '../../api/Scenarios/Scenario';
import apiExperiments from '../../api/Experiments/apiExperiments';
import apiScenarios from '../../api/Scenarios/apiScenarios';
import ActionLog from '../../api/ActionLogs/ActionLog';
import apiSentimentLogs from '../../api/SentimentLogs/apiSentimentLogs';
import apiActionLogs from '../../api/ActionLogs/apiActionLogs';
import apiScenes from '../../api/Scenes/apiScenes';
import Scene from '../../api/Scenes/Scene';
import {
  ReportContentWrapper,
  heatMapWrapper,
  reportScenarioRow,
  reportScenarioRowGraphs,
  reportScenarioRowTable,
  reportSummaryRow,
} from './ReportPage.Styles';
import ReportInfo from '../ReportInfo/ReportInfo';
import ReportScenarioSummary from '../ReportScenarioSummary/ReportScenarioSummary';
import {getDuration, getDurationText} from '../../Utils/ReportUtils';
import ReportActionLogsTable from '../ReportActionLogsTable/ReportActionLogsTable';

function ReportPage() {
  const {trialId} = useParams();
  const navigate = useNavigate();

  const [selectedTrial, setSelectedTrial] = useState<Trial | undefined>(undefined);
  const [experiment, setExperiment] = useState<Experiment | undefined>(undefined);
  const [scenarios, setScenarios] = useState<Scenario[]>([] as Scenario[]);
  const [scene, setScene] = useState<Scene | undefined>(undefined);
  const [selectedScenario, setSelectedScenario] = useState<Scenario | undefined>(undefined);
  const [sentimentLogs, setSentimentLogs] = useState<SentimentLog[]>([] as SentimentLog[]);
  const [actionLogs, setActionLogs] = useState<ActionLog[]>([] as ActionLog[]);
  const [selectedSentiment, setSelectedSentiment] = useState<SentimentLog | undefined>(undefined);

  useEffect(() => {
    if (trialId) {
      apiTrials.getTrial(trialId).then((response) => {
        setSelectedTrial(response);
      });

      apiSentimentLogs.getSentimentLogsForTrial(trialId).then((response) => {
        setSentimentLogs(response);
      });

      apiActionLogs.getActionLogsForTrial(trialId).then((response) => {
        setActionLogs(response);
      });
    }
  }, [trialId]);

  useEffect(() => {
    if (selectedTrial && selectedTrial.id) {
      apiExperiments.getExperiment(selectedTrial.experimentId).then((response) => {
        setExperiment(response);
      });
    }
  }, [selectedTrial]);

  useEffect(() => {
    if (experiment && experiment.id) {
      apiScenarios.getScenariosForExperiment(experiment.id).then((response) => {
        setScenarios(response);
        setSelectedScenario(response[0]);
      });
    }
  }, [experiment]);

  useEffect(() => {
    if (selectedScenario && selectedScenario.id) {
      apiScenes.getScene(selectedScenario.sceneId).then((response) => {
        setScene(response);
      });

      setSelectedSentiment(undefined);
    }
  }, [selectedScenario]);

  const selectScenario = (scenarioToSelect: Scenario) => {
    setSelectedScenario(scenarioToSelect);
  };

  const getActionLogsForScenario = (scenarioId: string): ActionLog[] => filter(
    actionLogs,
    (log) => log.scenarioId === scenarioId,
  );

  const getSentimentLogsForScenario = (scenarioId: string): SentimentLog[] => filter(
    sentimentLogs,
    (log) => log.scenarioId === scenarioId,
  );

  const returnToExperiments = () => {
    navigate(`/experiments/${experiment?.id}`);
  };

  const getHeatMapWrapperStyles = () => ({
    backgroundImage: `url("data:${scene?.imageMimeType};base64, ${scene?.imageStringBase64}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  });

  const selectSentiment = (sentiment?: SentimentLog) => {
    if (sentiment && selectedSentiment?.id === sentiment.id) {
      setSelectedSentiment(undefined);
    } else {
      setSelectedSentiment(sentiment);
    }
  };

  const getSelectedLogText = (): ReactNode | undefined => {
    let logText;

    if (selectedSentiment && selectedSentiment.transcript.text) {
      logText = (
        <Box>
          <Typography>Log Transcript: at {dayjs(selectedSentiment.created).format('mm:ss')}</Typography>
          <Typography variant="h4">{selectedSentiment.transcript.text}</Typography>
        </Box>
      );
    }

    return logText;
  };

  const getScenarioSummary = (scenario: Scenario, index: number): ReactNode => (
    <ReportScenarioSummary
      key={scenario.id}
      scenario={scenario}
      sentimentLogs={getSentimentLogsForScenario(scenario.id || '')}
      testLabel={index ? 'B' : 'A'}
      duration={getDuration(getActionLogsForScenario(scenario.id || ''))}
      selectScenario={(nextScenario) => selectScenario(nextScenario)}
    />
  );

  const getExperimentDurationString = (): string => {
    let totalDuration = 0;
    forEach(scenarios, (scenario) => {
      totalDuration += getDuration(getActionLogsForScenario(scenario.id || ''));
    });

    return getDurationText(totalDuration);
  };

  const getScenarioSummaries = (): ReactNode[] => map(
    scenarios,
    (scenario, index) => getScenarioSummary(scenario, index),
  );

  const getScenarioLabel = (id: string): 'A' | 'B' => {
    const index = findIndex(scenarios, (scenario) => id === scenario.id);
    return index ? 'B' : 'A';
  };

  return (
    <Suspense>
      <Box sx={appWrapper}>
        <NavBar title="Report" />
        <Box component="main" sx={ReportContentWrapper}>
          <Box sx={reportSummaryRow}>
            <ReportInfo
              trial={selectedTrial}
              experimentName={experiment?.name}
              duration={getExperimentDurationString()}
              onReturn={() => returnToExperiments()}
            />
            {getScenarioSummaries()}
          </Box>
          <Box sx={reportScenarioRow}>
            <Box sx={reportScenarioRowGraphs}>
              <Typography variant="h3">{scene?.name}</Typography>
              <Box sx={[heatMapWrapper, getHeatMapWrapperStyles()]}>
                <HeatMap
                  selectedScenario={selectedScenario}
                  actionLogs={getActionLogsForScenario(selectedScenario?.id || '')}
                />
              </Box>
              <Timeline
                actionLogs={getActionLogsForScenario(selectedScenario?.id || '')}
                sentimentLogs={getSentimentLogsForScenario(selectedScenario?.id || '')}
                selectSentiment={(sentiment) => selectSentiment(sentiment)}
                selectedSentiment={selectedSentiment}
              />
              {getSelectedLogText()}
            </Box>
            <Box sx={reportScenarioRowTable}>
              <ReportActionLogsTable
                testLabel={getScenarioLabel(selectedScenario?.id || '')}
                actionLogs={getActionLogsForScenario(selectedScenario?.id || '')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Suspense>
  );
}

export default Secure(ReportPage);
