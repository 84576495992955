import {panel} from '../../styles/styles';
import panelHeader from '../PanelHeader/PanelHeader.Style';

const sceneAssetPanel = {
  ...panel,
  alignSelf: 'stretch',
  flex: '1 0',
};

const sceneAssetPanelHeader = {
  ...panelHeader,
  alignSelf: 'stretch',
  justifyContent: 'space-between',
};

const sceneAssetPanelBody = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignSelf: 'stretch',
  gap: 2,
  padding: 2,
  alignContent: 'top',
};

export {
  sceneAssetPanel,
  sceneAssetPanelHeader,
  sceneAssetPanelBody,
};
