import tupeloApi from '../helpers';
import Scene from './Scene';

const getScenesForClient = async (clientId: string): Promise<Scene[]> => {
  const result = await tupeloApi.get(`/clients/${clientId}/scenes`);
  return result.data as Scene[];
};

const addScene = async (scene: Scene): Promise<Scene> => {
  const result = await tupeloApi.post('/scenes', scene);
  return result.data as Scene;
};

const updateScene = async (scene: Scene): Promise<Scene> => {
  const result = await tupeloApi.put(`/scenes/${scene.id}`, scene);
  return result.data as Scene;
};

const getScene = async (sceneId: string): Promise<Scene> => {
  const result = await tupeloApi.get(`/scenes/${sceneId}`);
  return result.data as Scene;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getScenesForClient,
  addScene,
  updateScene,
  getScene,
};
