/* eslint-disable react/function-component-definition */
import React, {FC, useEffect, useState} from 'react';
import {useCognitoAuth} from '../../hooks/useCognitoAuth';
import Loading from '../Loading/Loading';
import RenderContext from './RenderContext';
import {HeaderProps} from '../../interfaces/HeaderProps';

const PrivateRoute: FC<HeaderProps> = ({children}) => {
  const {isCognitoLoading} = useCognitoAuth(); // Cognito Auth context
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(isCognitoLoading);
  }, [isCognitoLoading]);

  return isLoading ? <Loading /> : <RenderContext>{children}</RenderContext>;
};

export default PrivateRoute;
