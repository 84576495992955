import tupeloApi from '../helpers';
import Experiment from './Experiment';
import NewExperimentDto from './NewExperimentDto';
import UpdateExperimentDto from './UpdateExperimentDto';

const createExperiment = async (experiment: NewExperimentDto): Promise<Experiment> => {
  const result = await tupeloApi.post('/experiments', experiment);
  return result.data as Experiment;
};

const getExperimentsForClient = async (clientId: string): Promise<Experiment[]> => {
  const result = await tupeloApi.get(`/client/${clientId}/experiments`);
  return result.data as Experiment[];
};

const getExperiment = async (experimentId: string): Promise<Experiment> => {
  const result = await tupeloApi.get(`/experiments/${experimentId}`);
  return result.data as Experiment;
};

const updateExperiment = async (experiment: UpdateExperimentDto): Promise<Experiment> => {
  const result = await tupeloApi.put('/experiments', experiment);
  return result.data as Experiment;
};

export default {
  createExperiment,
  getExperiment,
  updateExperiment,
  getExperimentsForClient,
};
