import {ArrowBackIosNew, Add} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {ReactNode} from 'react';
import {map} from 'lodash';
import {avatarActionButton} from '../../styles/styles';
import NavBarListItem from './NavBarListItem';
import {
  NavBarListAddButton,
  NavBarListDrawer,
  NavBarListItemLabel,
  NavBarListList,
  NavBarListListItem,
  NavBarListTitle,
  navBarListWrapper,
} from './NavBarList.Style';

type NavBarListProps = {
  listTitle?: string;
  showBack: boolean;
  items: NavBarListItem[];
  selectedItem?: any | undefined;
  selectListItem?: (itemId: string) => any;
  addNew?: () => any;
  backAction?: () => any;
};

function NavBarList(props: NavBarListProps) {
  const getNavBarListItem = (item: NavBarListItem): ReactNode => (
    // JB: Note that we have to wrap the tooltip text in a span to
    //     get it to properly parse a \n line break
    <Tooltip
      key={item.itemId}
      title={item.tip ? (<span style={{whiteSpace: 'pre-line'}}>{item.tip}</span>) : 'No scheduled tests'}
    >
      <ListItem
        sx={NavBarListListItem}
        className={item === props.selectedItem ? 'current' : ''}
        onClick={() => props.selectListItem?.(item.itemId)}
      >
        <ListItemAvatar>
          {item.icon}
        </ListItemAvatar>
        <ListItemText>
          <Typography sx={NavBarListItemLabel}>{item.label}</Typography>
        </ListItemText>
        {item.badge ?? <Typography>{item.badge}</Typography>}
      </ListItem>
    </Tooltip>
  );

  const getNavBarListItems = (items: NavBarListItem[]): ReactNode[] => map(
    items,
    (item: NavBarListItem) => getNavBarListItem(item),
  );

  const getBackButton = (showBack: boolean): ReactNode | undefined => {
    let backButton;

    const onClick = () => {
      if (props.backAction) {
        props.backAction();
      }
    };

    if (showBack) {
      backButton = (
        <IconButton aria-label="back" onClick={() => onClick()}>
          <ArrowBackIosNew />
        </IconButton>
      );
    }

    return backButton;
  };

  const fireAddNewButton = (): void => {
    props.addNew?.();
  };

  const getListTitle = (): ReactNode | undefined => {
    let listTitle;

    if (props.listTitle) {
      listTitle = (
        <Box sx={NavBarListTitle}>
          {getBackButton(props.showBack)}
          <Typography variant="h3">{props.listTitle}</Typography>
        </Box>
      );
    }

    return listTitle;
  };

  return (
    <Drawer
      variant="permanent"
      sx={NavBarListDrawer}
    >
      {getListTitle()}
      <Box sx={navBarListWrapper}>
        <List sx={NavBarListList}>
          {getNavBarListItems(props.items)}
        </List>
      </Box>
      <Box sx={NavBarListAddButton}>
        <Avatar>
          <IconButton
            aria-label="add experiment"
            sx={avatarActionButton}
            onClick={() => fireAddNewButton()}
          >
            <Add />
          </IconButton>
        </Avatar>
      </Box>
    </Drawer>
  );
}

export default NavBarList;
