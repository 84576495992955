const agencyClientItem = {
  display: 'flex',
  flexDirection: 'row',
  flex: '0 1',
  gap: 1,
  alignContent: 'middle',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: '#1A1B2114',
  },
  cursor: 'pointer',
};

const agencyClientIcon = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  flex: 0,
};

const agencyClientMiniDetail = {
  display: 'flex',
  flexDirection: 'column',
  flex: 6,
};

export {
  agencyClientItem,
  agencyClientIcon,
  agencyClientMiniDetail,
};
