import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import Client from '../../api/Clients/Client';
import {selectedClientHeaderRow} from './AgencySelectedClientPanel.Style';
import {contentAreaHeader} from '../../styles/styles';

type AgencySelectedClientsPanelProps = {
  client: Client;
  goToClientPage: (id: string) => any;
  cancel: () => any;
}

function AgencySelectedClientsPanel(props: AgencySelectedClientsPanelProps) {
  const fireCancel = () => {
    props.cancel();
  };

  const fireGoto = () => {
    props.goToClientPage(props.client.id || '');
  };

  return (
    <Box sx={contentAreaHeader}>
      <Box sx={selectedClientHeaderRow}>
        <Typography variant="h3">{props.client.name}</Typography>
      </Box>
      <Box sx={selectedClientHeaderRow}>
        <Button onClick={() => fireCancel()}>Cancel</Button>
        <Button onClick={() => fireGoto()}>Go to Clients</Button>
      </Box>
    </Box>
  );
}

export default AgencySelectedClientsPanel;
