import React from 'react';
import {Box, Button} from '@mui/material';
import {panelControlsFooter, controlButton} from './PanelControlsFooter.Style';

type PanelControlsFooterProps = {
  isSubmit?: boolean;
  onCancel: () => any;
  onSave: () => any;
  saveDisabled: () => boolean;
}

function PanelControlsFooter(props: PanelControlsFooterProps) {
  const fireCancel = () => {
    props.onCancel();
  };

  const fireSave = () => {
    props.onSave();
  };

  return (
    <Box sx={panelControlsFooter}>
      <Button
        sx={controlButton}
        variant="text"
        onClick={() => fireCancel()}
      >
        Cancel
      </Button>
      <Button
        sx={controlButton}
        variant="contained"
        type={(props.isSubmit && props.isSubmit === true) ? 'submit' : 'button'}
        onClick={() => fireSave()}
        disabled={props.saveDisabled()}
      >
        Save
      </Button>
    </Box>
  );
}

export default PanelControlsFooter;
