import React, {Suspense} from 'react';
import {RouterProvider} from 'react-router-dom';
import {ThemeProvider} from '@emotion/react';
import {CssBaseline} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import routes from './routes/routes';
import theme from './Themes/theme';

function App() {
  return (
    <Suspense>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <RouterProvider router={routes} />
        </ThemeProvider>
      </LocalizationProvider>
    </Suspense>
  );
}

export default App;
