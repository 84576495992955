import React from 'react';
import {Box, Typography} from '@mui/material';
import {ArrowRight, StoreOutlined} from '@mui/icons-material';
import {
  agencyClientIcon,
  agencyClientItem,
  agencyClientMiniDetail,
} from './AgencyClientListItem.Styles';
import Client from '../../api/Clients/Client';

type AgencyClientListItemProps = {
  client: Client;
  onAction?: (id: string) => any;
};

function AgencyClientListItem(props: AgencyClientListItemProps) {
  const fireClientClick = () => {
    props.onAction?.(props.client.id || '');
  };

  return (
    <Box sx={agencyClientItem} onClick={() => fireClientClick()}>
      <Box sx={agencyClientIcon}>
        <StoreOutlined />
      </Box>
      <Box sx={agencyClientMiniDetail}>
        <Typography variant="h4">{props.client.name}</Typography>
      </Box>
      <Box sx={agencyClientIcon}>
        <ArrowRight />
      </Box>
    </Box>
  );
}

export default AgencyClientListItem;
