import React from 'react';
import {Box, Typography} from '@mui/material';
import {ArrowRight, StoreOutlined} from '@mui/icons-material';
import {
  agencySceneIcon,
  agencySceneItem,
  agencySceneMiniDetail,
} from './AgencySceneListItem.Styles';
import Scene from '../../api/Scenes/Scene';

type AgencySceneListItemProps = {
  scene: Scene;
  onAction?: (id: string) => any;
};

function AgencySceneListItem(props: AgencySceneListItemProps) {
  const fireSceneClick = () => {
    props.onAction?.(props.scene.id || '');
  };

  return (
    <Box sx={agencySceneItem} onClick={() => fireSceneClick()}>
      <Box sx={agencySceneIcon}>
        <StoreOutlined />
      </Box>
      <Box sx={agencySceneMiniDetail}>
        <Typography variant="h4">{props.scene.name}</Typography>
      </Box>
      <Box sx={agencySceneIcon}>
        <ArrowRight />
      </Box>
    </Box>
  );
}

export default AgencySceneListItem;
