import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#436833',
      light: '#DFE4D7',
      contrastText: '#fff',
    },
    secondary: {
      main: '#191D17',
      light: '#43483E',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#F8FAF0',
      paper: '#F8FAF0',
    },
    action: {
      hover: '#4368331F',
      selected: '#4368331F',
    },
  },
  typography: {
    body1: {
      fontFamily: 'Roboto',
      fontSize: '0.875rem',
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '1.25rem',
      letterSpacing: '0.00625rem',
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1rem',
    },
  },
});

export default theme;
