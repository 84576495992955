// A bunch of common styles ed as objects

/** *********************
 * App.js
 *********************** */

const appWrapper = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
};

const contentWrapper = {
  display: 'flex',
  padding: '1rem 0rem',
  alignItems: 'stretch',
  flex: '1 0',
  bgcolor: 'background.default ',
  flexDirection: 'row',
};

const contentAreaWrapper = {
  display: 'flex',
  padding: '0rem 1rem',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '0.5rem',
  flex: '1 0 0',
  maxWidth: '1000px',
  backgroundColor: 'background.default',
};

const contentAreaHeader = {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0',
  padding: '0.5rem',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  borderRadius: '0.625rem',
  background: 'rgba(255, 255, 255, 0.40)',
  boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.50)',
  backdropFilter: 'blur(5px)',
};

const avatarActionButton = {
  backgroundColor: 'primary.main',
  color: '#fff',
};

const headerTitle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: '1 0 0',
  paddingRight: '1rem',
  '& h3': {
    color: '#000',
    fontSize: '1.75rem',
    fontWeight: 400,
    lineHeight: '2.25rem',
    display: 'block',
  },
};

const contentBody = {
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
};

const panel = {
  display: 'flex',
  flex: '1 100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '0.625rem',
  background: 'rgba(255, 255, 255, 0.40)',
  boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.50)',
  backdropFilter: 'blur(5px)',
};

/** **********************
 * General styles
 *********************** */

const flex = {display: 'flex'};
const flexGrow = {display: 'flex', flex: '1 0'};

export {
  appWrapper,
  contentWrapper,
  contentAreaWrapper,
  contentAreaHeader,
  avatarActionButton,
  headerTitle,
  contentBody,
  panel,
  flex,
  flexGrow,
};
