const LoginScreen = {
  backgroundImage: 'url(/images/screenshot-20240226-at-3211.png)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  maxWidth: '100%',
  maxHeight: '100%',
  bottom: 0,
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
};

const CenteredLogin = {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
  margin: '20rem auto',
  '& form': {
    display: 'flex',
    padding: '1.5rem 3rem',
    flexDirection: 'column',
    flex: '1 0 0',
    gap: '1rem',
    margin: '0 auto',
  },
};

const LargeLogo = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '-0.75rem',
};

const LogoContentVertical = {
  backgroundImage: 'url(/images/logo-1.png)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '5.73769rem',
  margin: '1.5rem',
  paddingTop: '5.23769rem',
  fontFamily: 'Merriweather',
  fontSize: '2.25rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#000',
};

const LoginActions = {
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  justifyContent: 'space-between',
};

export {
  LoginScreen,
  CenteredLogin,
  LargeLogo,
  LogoContentVertical,
  LoginActions,
};
